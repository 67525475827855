import React from 'react'
import CustomCarousel from './CustomCarousel';
import CustomCard from './CustomCard';
import Whatsapp from './Whatsapp';


export default function Home() {
  return (
    <>
     
		<CustomCarousel/>
	    <Whatsapp/>
		<CustomCard />
    
		<br/>
			
    </>
  )
}
