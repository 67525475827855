import React from 'react'
import "./Whatsapp.css"
import img32 from './images/img32.jpg'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, CardImg } from "reactstrap";
import img from './images/img1.jpg';
import img2 from "./images/img2.jpg"
import img3 from "./images/img3.jpg"
import img4 from "./images/img4.jpg"
import img5 from "./images/img5.jpeg"
import img6 from "./images/img7.jpg"
import img7 from "./images/img8.jpeg"
import img8 from "./images/img8.jpg"
import img9 from "./images/img9.jpg"

import img15 from './images/img15.jpg';
import img16 from "./images/img16.jpg"
import img17 from "./images/img17.jpg"
import img18 from "./images/img18.jpg"
import img19 from "./images/img19.jpg"
import img20 from "./images/img20.jpg"


export default function ScubaDive() {
  return (

    <div>
   
  
   <div className="container text-center" style={{ backgroundColor: '#ECF0F1 ' }}>
                <div className="row g-3">
                    <div className="col-12 col-md-6 ">
                    <img src={img32} alt='scubadive' style={{width:'400px'}}/>
                    </div>
                    <div className="col">
                        
                    <img src={img} alt='scubadive'style={{width:'400px'}}/>



                    </div>

                </div>
            </div>

         <div className="container text-center" style={{ backgroundColor: '#ECF0F1 ' }}>
                <div className="row g-3">
                    <div className="col-12 col-md-6 ">
                        <h5 style={{ color: '#000080' }}> Welcome to ScubaSports </h5>
                        <hr />

                        <h3 style={{ color: '#D80E67 ' }}> Goa Scuba Diving & Water Sports.</h3>
                        <hr />
                        <p style={{ textAlign: 'justify' }}>Goa Scuba diving experience should be on everyone’s list of must-to-do activities in Goa. ScubaSports is famous for providing one of a kind experience when it comes to water sports and scuba diving in Goa With a depth ranging from 10-15 meters.

                            <br /><br />  At ScubaSports we thrive to provide the best 1st scuba experience in goa.  we have been here for 5+ years and serve thousands of travellers every season.<br /><br />
                            <b> Nothing is more enjoyable in Goa. Just Come and enjoy your best day over the Arabian Sea. </b>
                        </p>
                    </div>
                    <div className="col">
                        <h5 style={{ color: '#000080' }}> Get the Best Experience in Scuba Diving & Watersports</h5>
                        <hr />
                        <h3 style={{ color: '#D80E67 ' }}>Best Service, We Strive To Provide Our Customers.</h3>
                        <hr />
                        <ul style={{ textAlign: 'justify' }}>
                            <li>Real Scuba Experience</li>
                            <hr />
                            <li>Fun rides in Watersports</li>
                            <hr />
                            <li>Professional & Expert Instructors</li>
                            <hr />
                            <li>Quality & Best Equipment's</li>
                            <hr />
                            <li>Instant Booking Confirmation on Phone</li>
                            <hr />
                            <li>Pre Booking Discount available</li>
                            <hr />
                            <li>Certified & Safe activities</li>
                            <hr />
                            <li>Enjoy Best Day in Goa.</li>
                        </ul>
                        <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary" style={{ backgroundColor: '#8C0ED8 ' }}>Get more videos and Details on Phone</a>



                    </div>

                </div>
            </div>

             {/*pack1*/}
            <br />
            <br />
            <hr />
            <h1 style={{ color: '#000080' }}> Bestselling Packages</h1>
            <br />

            <h5 style={{ color: '#D80E67 ' }}>Select the best scuba diving in goa package and get a special discount on pre-bookings.</h5>
            <br />
            <div style={{ backgroundColor: '#ECF0F1 ' }}>

                <div className="container text-center">
                    <div className="row g-3">
                        <div className="col-12 col-md-6">
                            <br />
                            <h5 style={{ color: '#000080', }}>
                                Scuba Diving in Goa with Water Sports, Grand Island Unlimited Package</h5>
                            <Card>
                                <CardImg
                                    alt="Card image cap"
                                    src={img}
                                    top
                                    width="100%"
                                />
                                <CardBody>
                                    <CardTitle tag="h5">
                                        Scuba Diving in GOA
                                    </CardTitle>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h6"
                                    >
                                        <h6>Price:<del>1000 INR</del></h6>
                                    </CardSubtitle>
                                    <CardText>
                                        Offer Price : 599 INR/Person
                                    </CardText>

                                </CardBody>
                            </Card>
                            
                        </div>
                        <div className="col">
                            <br />
                            <h5 style={{ color: '#000080', }}>
                                Highlights ( 8 Activities Included – Best First Experience)</h5>
                            <ul style={{ textAlign: 'justify' }}>

                                <li>Trip to Grand island</li>
                                <li>40 Km Sea Trip</li>
                                <li>White Water Deep-Sea Dive.</li>
                                <li>Scuba Diving</li>
                                <li>Scuba Training by PADI Certified Trainer</li>
                                <li>Parasailing With Dip For Free</li>
                                <li>Jet Ski</li>
                                <li>Banana Ride</li>
                                <li>Bumper Ride</li>
                                <li>Speed Boat</li>
                                <li>Underwater HD Video GoPro8</li>
                                <li>Free Pickup/Drop from Hotel</li>
                                <li>Beers, Water, Breakfast</li>
                                <li>Delicious Lunch Non-Veg/ Veg</li>
                                <li>Equipment Rental</li>
                            </ul>
                            <div>
                                <br />
                                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary" style={{ backgroundColor: '#8C0ED8 ' }}>Get more videos and Details on Phone</a><br />
                                <br />
                                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary" style={{ backgroundColor: '#8C0ED8 ' }}>Book Now</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            {/*pack2*/}
            <br />
            <div style={{ backgroundColor: '#ECF0F1' }}>

                <div className="container text-center">
                    <div className="row g-2">
                        <div className="col-12 col-md-6">
                            <br />
                            <h5 style={{ color: '#000080', }}>
                                Monsoon Scuba Diving in Goa with Watersports Package</h5>
                                <Card>
                                <CardImg
                                    alt="Card image cap"
                                    src={img32}
                                    top
                                    width="100%"
                                />
                                <CardBody>
                                    <CardTitle tag="h5">
                                        Scuba Diving in GOA
                                    </CardTitle>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h6"
                                    >
                                        <h6>Price:<del>1000 INR</del></h6>
                                    </CardSubtitle>
                                    <CardText>
                                        Offer Price : 599 INR/Person
                                    </CardText>

                                </CardBody>
                            </Card>
                            

                        </div>
                        <div className="col">
                            <br />
                            <h5 style={{ color: '#000080', }}>
                                Highlights ( 6 Activities Included – Best First Experience)</h5>
                            <ul style={{ textAlign: 'justify' }}>

                                <li>Watersports</li>
                                <li>Scuba Diving</li>
                                <li>Scuba Training by PADI Certified Trainer</li>
                                <li>Jet Ski</li>
                                <li>Banana Ride</li>
                                <li>Bumper Ride</li>
                                <li>Speed Boat</li>
                                <li>Underwater HD Video GoPro8</li>
                                <li>Free Pickup/Drop from Hotel</li>
                                <li>Snacks, Water, Breakfast</li>
                                <li>Delicious Lunch Non-Veg/ Veg</li>
                                <li>Equipment Rental</li>
                            </ul>
                            <div>
                                <br />
                                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary" style={{ backgroundColor: '#8C0ED8 ' }}>Get more videos and Details on Phone</a><br />
                                <br />
                                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary" style={{ backgroundColor: '#8C0ED8 ' }}>Book Now</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br/>
            <div className="container text-center">
        <div className="row g-3 ">
          <div className="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Scuba Diving in GOA
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  <h6>Price:<del>1000 INR</del></h6>
                </CardSubtitle>
                <CardText>
                  Offer Price : 599 INR/Person
                </CardText>
                <div >
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>

                </div>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img2}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Speed Boat
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price: <del>1500 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 999 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
               

               
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img3}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Boat Cruise (Adventure Party)
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>1500 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 999 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <br></br>
      <div class="container text-center">
        <div class="row g-3">
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img4}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Parasailing
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>1500 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 999 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img5}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Top 5 Water Sports Combo
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>300 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 150 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img6}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Dinner Cruise in GOA
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>2400 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 1800 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <br></br>
      <div class="container text-center">
        <div class="row g-3">
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img7}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Top 5 Water Sports Combo
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>300 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 150 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img8}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  DudhSagar Waterfall+Jeep Safari
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>2400 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 1900 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img9}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  DudhSagar Waterfall+Jeep Safari

                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>2400 INR</del>

                </CardSubtitle>
                <CardText>
                  Offer Price : 1900 INR/Person

                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <br></br>
      <div className="container text-center">
        <div className="row g-3 ">
          <div className="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img15}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  9D Cinema Beach Experience
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  <h6>Price:<del>300 INR</del></h6>
                </CardSubtitle>
                <CardText>
                  Offer Price : 150 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img16}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Fly Boarding 
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price: <del>4200 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 3499 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img17}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Sub Boarding
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>4200 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 3499 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <br></br>
      <div class="container text-center">
        <div class="row g-3">
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img18}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Highest Bungee Jumping
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>4200 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 3499 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img19}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  NorthGOA sightseeing+Private Car
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>5000 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 4499 INR/4pax/1day
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <Card>
              <CardImg
                alt="Card image cap"
                src={img20}
                top
                width="100%"
              />
              <CardBody>
                <CardTitle tag="h5">
                  Snow Park full Party
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Price:<del>1000 INR</del>
                </CardSubtitle>
                <CardText>
                  Offer Price : 599 INR/Person
                </CardText>
                <a href="https://wa.me/7703843611?text=Hi%20%2C%20It's%20great%20to%20know%20about%20Scubasport%20%2C%20can%20you%20send%20me%20more%20details%20about%20your%20package%3F" className="btn btn-primary">More Details</a>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

    </div>
  )
}
